import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Nav } from "../../components";
import { WorkSectionHeader } from "../../components";
import GlobalLayout from "../../layouts/GlobalLayout";
import landingGif from "../../images/work/df/landing.gif";
import nothingGif from "../../images/work/df/nothing.gif";

const DF = () => {
  const imgStyleOutline = {
    boxShadow: "0px 4px 32px #00000015",
    maxWidth: "1280px",
    // marginLeft: '50vw',
    placeSelf: "center",
    border: "1px solid black",
    marginTop: "3.33em",
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType="Product"
        workName="Disentropic Foundry"
        what="Hyperdimensional material foundry specializing in energy condensation onto Bitcoin Inscriptions."
        who="Confidential"
        role="Brand & Product Design"
        shoutout="HongKong97, Wags, &amp; Float"
      />
      <ShowcaseContainer>
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/df/logoSlide.png"
        />
        <img
          alt="lolololololololololololololololol"
          style={{ imgStyleOutline }}
          src={landingGif}
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/df/v0Landing.png"
        />
        <img
          alt="lolololololololololololololololol"
          style={{ imgStyleOutline }}
          src={nothingGif}
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/df/showcase.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/df/thecubit.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/df/explainer.png"
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  justify-content: center;
`;
export default DF;
